// 内存泄漏检测结果展示
import React, { useState } from 'react';
import {
  Card,
  Layout,
  Modal,
} from '@tencent/tea-component';
import {
  List,
  Link,
  Space,
  Tag,
  Tooltip,
} from 'tdesign-react';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import qs from 'qs';
import { CallStackGraph } from '../memleakResult';
import './index.css';
import { getFlameGraphURL, jumpTargetConfig } from '@/utils/URL';
import { TASK_NAME_MAP } from '@/config';
import { taskTimeFormat } from '@/utils/DateTime';
const { ListItem } = List;
// import { TaskInfoCard } from '@/components/taskInfo';

const mp = {
  0: '通用分析',
  1: 'Java采样分析',
  2: '磁盘追踪',
  3: '时序分析',
  4: '内存泄漏检测',
  6: 'Java堆分析',
};
const { Body, Content } = Layout;

const DynamicDetectionResult = (props) => {
  const [showCallTreeStatus, setShowCallTreeStatus] = useState(false);
  const [callTreeData, setCallTreeData] = useState(false);

  Log.debug('DynamicDetectionResult', props);
  const DynamicDetectionSubCardArray = [];
  Object.keys(props.all_analysis_suggestions).forEach((key) => {
    DynamicDetectionSubCardArray.push(<DynamicDetectionSubCard
        analysis_suggestions={props.all_analysis_suggestions[key]}
        title={key}
        showCallTree={(calltreeData) => {
          setShowCallTreeStatus(true);
          setCallTreeData(calltreeData);
        }}
      />);
  });
  return <>
    <Layout className="demo-layout">
      <Body>
        <Content>
          {props.showGobackButton
            ? <Content.Header
              showBackButton
              onBackButtonClick={() => props.goback()}
              title="返回"
            >
            </Content.Header> : ''}
          <Content.Body>
            {DynamicDetectionSubCardArray}
          </Content.Body>
        </Content>
      </Body>
    </Layout>
    <Modal visible={showCallTreeStatus} caption="调用栈" onClose={() => setShowCallTreeStatus(false)}>
      <Modal.Body>
        <CallStackGraph call_stacks={callTreeData}></CallStackGraph>
      </Modal.Body>
    </Modal>
  </>;
};

DynamicDetectionResult.propTypes = {
  task_info: PropTypes.object,
  goback: PropTypes.func,
  showGobackButton: PropTypes.bool,
  jupyter_url: PropTypes.string,
  analysis_version: PropTypes.string,
  all_analysis_suggestions: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
};

DynamicDetectionResult.defaultProps = {
  showGobackButton: true,
  jupyter_url: '',
  analysis_version: '1.0.0',
  analysis_suggestions: [],
};


const DynamicDetectionSubCard = (props) => {
  Log.debug('DynamicDetectionSubTaskCard', props);
  const args = qs.parse(window.location.search.slice(1));
  const suggestions = [];
  const analysisSuggestions = props.analysis_suggestions || [];
  // 任务按照begin_time进行排序
  analysisSuggestions.sort((a, b) => new Date(a.begin_time.Time) - new Date(b.begin_time.Time));

  for (let index = 0; index < analysisSuggestions.length; index++) {
    const element = analysisSuggestions[index];
    const flameGraphURL = function (funcName) {
      const url = getFlameGraphURL(window.location, funcName);
      url.searchParams.set('taskID', element.tid);
      return url.toString();
    };

    let subSuggestions = <ListItem >
      <Tag size="large" theme="success" variant="light">
        暂无建议
      </Tag></ListItem>;
    // memleak和锁分析会产生无效建议，暂时在这里用匹配屏蔽掉；
    // 例如suggestion.suggestion="暂未发现泄漏点"，直接匹配"暂未发现"
    if (element.suggestions.length) {
      if (!(element.suggestions.length === 1 && element.suggestions[0]?.suggestion.includes('暂未发现'))) {
        subSuggestions = element.suggestions.map((suggest, key) => <ListItem key={key} action={
          <Space>
            {suggest?.func !== '' && suggest?.func !== undefined && element.type === TASK_NAME_MAP.ProfilingGeneralTask ? <Link
                href={flameGraphURL(suggest.func)}
                theme="primary" hover="color" target={jumpTargetConfig()}>
              火焰图
            </Link> : ''}
          </Space>
        }><Tag size="large" theme="danger" variant="light">{suggest?.suggestion}</Tag></ListItem>);
      }
    }
    const beginTime = taskTimeFormat(element?.begin_time);
    const endTime = taskTimeFormat(element?.end_time);
    let timeDescription = <ListItem>
      开始时间: { beginTime } | 结束时间: { endTime }
    </ListItem>;
    if (beginTime === endTime) {
      timeDescription = <ListItem><Tooltip
        content="开始时间和结束时间一致是因为这是一个文件上传分析任务"
        destroyOnClose
        duration={0}
        placement="top"
        showArrow
        theme="default"
        >开始时间: { beginTime } | 结束时间: { endTime }
      </Tooltip></ListItem>;
    }
    suggestions.push(<div className='drop-flow-block'>
      <div className='title'>{`检测项: ${mp[element.type]}`}</div>
      <ListItem action={
        <Space>
          <Link theme="primary" hover="color" target={jumpTargetConfig()}
                href={
                  `/task/result?${qs.stringify(Object.assign(args, {
                    taskID: element.tid, jumped: true }))}`}>查看详情</Link>
        </Space>
      }>任务名: {element?.name} ｜ 目标IP: {element?.target_ip}</ListItem>
      { timeDescription }
      <List key={index} split={true} >
        {subSuggestions}
      </List>
    </div>);
  }
  let result = '';
  if (props.analysis_suggestions.length > 0) {
    result = <Card>
      <Card.Body title={props.title} >
        {suggestions}
      </Card.Body>
    </Card>;
  }
  return result;
};

DynamicDetectionSubCard.propTypes = {
  analysis_suggestions: PropTypes.array,
  title: PropTypes.string,
  showCallTree: PropTypes.func,
  sharedTask: PropTypes.bool,
};

export default DynamicDetectionResult;
