import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { HOST_URL } from '@/config/';
import {
  Dialog,
  MessagePlugin,
  Switch,
} from 'tdesign-react';
import { goodStatus } from '@/utils/ErrorConfirm';
import StartTaskForm from './startTaskForm';
import StartTaskFormV2 from './startTaskFormV2';
import { TASK_NAME_MAP } from '@/config';

const fileType = {
  perfScriptData: 1,
  pprofData: 2,
};

// genFileType 文件类别
const genFileType =  (value) => {
  if (value.task_type === TASK_NAME_MAP.ProfilingGeneralTask) {
    if (value.profiler_type === 2) {
      return fileType.pprofData;
    }
    return fileType.perfScriptData;
  }
  return 0;
};

const StartTaskComponent = (props) => {
  const useV2 = localStorage.getItem('useV2StartTaskForm') !== 'false';
  const [startTaskFormKey, setStartTaskFormkey] = useState(false);
  const [useV2StartTaskForm, setUseV2StartTaskForm] = useState(useV2);

  // 生成axiosconfig
  const genAxiosConfig = (value) => {
    let param = {};
    let url = '';

    // 批量单任务使用复合任务接口发起
    if (value.target_host?.length !== 1) {
      const tasks = [];
      value.target_host.forEach((item) => {
        tasks.push({
          target_ip: item,
          task_type: value.task_type,
          wide_options: value.perf_wide || value.tracing_wide || 'system',
          freq: Number(value.profiling_freqence || 0),
          duration: Number(value.duration),
          pid: Number(value.pid || -1),
          perf_record_profiler_type: value.profiler_type || 0,
          container_name: value.container_name || '',
          container_type: value.container_type || 0,
          comm: value.comm || '',
          port: Number(value.port || 0),
          annotate_enable: value.annotate_enable,
          trace_object: value.trace_object || 'c',
          record_subprocess: value.record_subprocess,
          callgraph_option: value.callgraph,
          file_type: genFileType(value),
        });
      });

      param = {
        task_name: `${value.name} - 批量`,
        task_list: JSON.stringify(tasks),
      };
      url = value.schedule_time ? `${HOST_URL}/api/v1/schedule/multiTask` : `${HOST_URL}/api/v1/multiTask`;
    } else if (value.task_type === -1) {
      // 动态检测功能
      const tasks = [
        {
          target_ip: value.target_host[0],
          task_type: 0,
          wide_options: 'system',
          freq: 999,
          duration: Number(value.duration),
          pid: -1,
          perf_record_profiler_type: 0,
          container_name: '',
          container_type: 0,
          comm: '',
          port: Number(value.port || 0),
          file_type: genFileType(value),
          annotate_enable: true,
          trace_object: '',
          record_subprocess: false,
          callgraph_option: value.callgraph || 'fp',
        },
        {
          target_ip: value.target_host[0],
          task_type: 2,
          wide_options: 'system',
          freq: 99,
          duration: Number(value.duration),
          pid: -1,
          perf_record_profiler_type: 0,
          file_type: genFileType(value),
          container_name: '',
          container_type: 0,
          comm: '',
        },
        {
          target_ip: value.target_host[0],
          task_type: 3,
          wide_options: 'system',
          freq: 999,
          duration: Number(value.duration),
          pid: -1,
          perf_record_profiler_type: 0,
          file_type: genFileType(value),
          container_name: '',
          container_type: 0,
          comm: '',
        },
        {
          target_ip: value.target_host[0],
          task_type: 4,
          wide_options: value.tracing_wide || 'system',
          freq: 99,
          duration: Number(value.duration),
          pid: Number(value.pid || -1),
          perf_record_profiler_type: 0,
          file_type: genFileType(value),
          container_name: '',
          container_type: 0,
          comm: value.comm || '',
          port: 0,
          annotate_enable: false,
          trace_object: 'c',
          record_subprocess: false,
        },
      ];
      param = {
        task_name: value.name,
        target_hostname: '',
        parallel_enable: 0,
        task_list: JSON.stringify(tasks),
      };
      url = value.schedule_time ? `${HOST_URL}/api/v1/schedule/multiTask` : `${HOST_URL}/api/v1/multiTask`;
    } else if (value.task_type === -2) {
      // java分析中的内存采样+堆转储分析功能
      const tasks = [
        {
          target_ip: value.target_host[0],
          task_type: 1,
          perf_record_profiler_type: 1,
          wide_options: value.tracing_wide || 'system',
          freq: 99,
          event: 'alloc',
          duration: Number(value.duration),
          pid: Number(value.pid || -1),
          file_type: genFileType(value),
          container_name: '',
          container_type: 0,
          comm: '',
        },
        {
          target_ip: value.target_host[0],
          task_type: 6,
          wide_options: value.tracing_wide || 'system',
          freq: 99,
          pid: Number(value.pid || -1),
          file_type: genFileType(value),
          container_name: '',
          container_type: 0,
          comm: '',
        },
      ];
      param = {
        task_name: value.name,
        target_hostname: '',
        parallel_enable: 0,
        task_list: JSON.stringify(tasks),
      };
      url = value.schedule_time ? `${HOST_URL}/api/v1/schedule/multiTask` : `${HOST_URL}/api/v1/multiTask`;
    } else {
    // Todo: 后续移除if_perf_record_callgraph
      const taskDesc = {
        pprofmem_sourcepath: value.pprofmemsourcepath,
        name: value.name,
        perf_record_target_ip: value.target_host[0],
        perf_record_options: value.perf_wide || value.tracing_wide || 'system',
        perf_record_freq: Number(value.profiling_freqence || 0),
        perf_record_duration: Number(value.duration),
        perf_record_pid: Number(value.pid || -1),
        perf_record_type: value.task_type,
        perf_record_profiler_type: value.profiler_type || 0,
        container_name: value.container_name || '',
        container_type: value.container_type || 0,
        file_type: genFileType(value),
        if_perf_record_callgraph: true,
        comm: value.comm || '',
        port: Number(value.port || 0),
        command: value.command,
        annotate_enable: value.annotate_enable,
        // sourcefilepath_enable: value.sourcefilepath_enable,
        sourcefilepath_enable: false,
        trace_object: value.trace_object || 'c',
        record_subprocess: value.record_subprocess,
        callgraph_option: value.callgraph,
        event: value.event,
      };
      param = {
        target_ip: value.target_host[0],
        taskDesc: JSON.stringify(taskDesc),
        ip: value.target_host[0],
        try_local_task: true,
      };
      url = value.schedule_time ? `${HOST_URL}/api/v1/schedule/task` : `${HOST_URL}/api/v1/tasks`;
    }
    return {
      url,
      data: {
        ...param,
        schedule: value?.schedule_time || '',
        privilege: (!value?.privilege) || false,
      },
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    };
  };

  // axios转curl命令
  const encodeForCurl = (value) => {
    return encodeURIComponent(value);
  };

  const toFormUrlEncoded = (data) => {
      return Object.keys(data)
          .map(key => `${encodeForCurl(key)}=${encodeForCurl(data[key])}`)
          .join('&');
  };

  const axiosToCurl = (axiosConfig) => {
      const { method = 'get', url, headers, data } = axiosConfig;

      // URL 编码
      let curlCommand = `curl -k -X ${method.toUpperCase()} '${url}'`;

      // 添加请求头
      if (headers) {
          for (const [key, value] of Object.entries(headers)) {
              curlCommand += ` -H '${key}: ${value}'`;
          }
      }

      // 添加请求体
      if (data) {
          // 转换为 application/x-www-form-urlencoded 格式
          const encodedData = toFormUrlEncoded(data);
          curlCommand += ` -d '${encodedData}'`;
      }

      return curlCommand;
  };
  // 生成命令
  const genCurlCommand = (value, key) => {
    const axiosConfig = genAxiosConfig(value);
    if (key !== null) {
      axiosConfig.headers.Authorization = key;
    } else {
      axiosConfig.headers.Authorization = '{请联系管理员获取}';
    }
    return axiosToCurl(axiosConfig);
  };

  // 新建任务
  const startTask = (value) => {
    const axiosConfig = genAxiosConfig(value);
    axios(axiosConfig)
      .then((response) => {
        if (goodStatus(response)) {
          MessagePlugin.info('发起任务成功');
          props.successAction();
          setStartTaskFormkey(!startTaskFormKey);
        } else {
          MessagePlugin.error('发起任务失败，请刷新页面重试');
        }
      })
      .catch((error) => {
        MessagePlugin.error('发起任务失败(unknown error)，请刷新页面重试');
        console.log(error);
      });
    props.onClose();
  };

  const changeExpertButton = (v) => {
    setUseV2StartTaskForm(v);
    localStorage.setItem('useV2StartTaskForm', v.toString());
  };

  return <>
    <Dialog width="750px" header={
      <span>
        <text style={{ paddingRight: '10px' }}>专家模式</text>
        <Switch defaultValue={!useV2StartTaskForm}
                onChange={v => changeExpertButton(!v)}
                style={{ padding: '5px 5px', fontSize: '14px', cursor: 'pointer' }}>
        </Switch>
      </span>
    } visible={props.visable} footer={false} onClose={() => props.onClose()}>
      {useV2StartTaskForm ? <StartTaskFormV2
          key={startTaskFormKey}
          agents = {props.agents}
          onClick = {v => startTask(v) }>
        </StartTaskFormV2>
        : <StartTaskForm
          key={startTaskFormKey}
          agents = {props.agents}
          onClick = {v => startTask(v)}
          genCurlCommand = {(value, key) => {
            return genCurlCommand(value, key);
          }}/>}
    </Dialog>
  </>;
};

StartTaskComponent.propTypes = {
  agents: PropTypes.array,
  visable: PropTypes.bool,
  onClose: PropTypes.func,
  successAction: PropTypes.func,
};

StartTaskComponent.defaultProps = {
  successAction: () => {},
};

export default StartTaskComponent;
