// 调用栈组件

import { React, _useEffect, useState } from 'react';
import {
  Tooltip as _TDTooltip,
  _Popup,
  _Popconfirm,
  Button, _TagInput,
  _Tooltip, _Statistic,
  Collapse, Space,
  Card, Tag,
} from 'tdesign-react';
import { Log } from '@/utils/Log';
import PropTypes from 'prop-types';

// CallStackViewers 将调用栈转换成一系列的调用栈渲染
// Param: {
//  "foo;bar": 2324,
//  "main;foo;bar": 231,
// }
// Return:
//  调用栈组件
export const CallStackViewers = (props) => {
  Log.debug("CallStackViewers", props);
  const { Panel } = Collapse;
  const stacksObjects = props.stacksObjects;
  const callStackViewersData = [];
  const callStackViewers = [];
  const [isFullContentVisible, setIsFullContentVisible] = useState(false);

  for (const stackStr in stacksObjects) {
    const stackList = stackStr.split(';');
    const num = stacksObjects[stackStr];
    const stacks = [];
    for (let i = 0; i < stackList.length; i++) {
      const currentStack = stackList[i];
      const currentStackData = {
        func: currentStack,
        type: "",
        file: "",
        popconfirm: "",
        sourceCode: false,
      };
      stacks.push(currentStackData);
    }
    stacks.reverse();
    callStackViewersData.push({
      stacks: stacks,
      num: num,
    });
  }
  // callStackViewersData 排序
  callStackViewersData.sort((a, b) => {
    return b.num - a.num;
  });
  for (let i = 0; i < callStackViewersData.length; i++) {
    const callStackViewerData = callStackViewersData[i];
    callStackViewers.push(
          CallStackViewer(callStackViewerData.stacks, callStackViewerData.num, props.type));
  }
  const toggleShowMore = () => {
    setIsFullContentVisible(!isFullContentVisible);
  };
  return <>
  <Collapse borderless>
    <Panel  accordion={true} header="调用栈">
      <>
    {/* {callStackViewers} */}
      {
      callStackViewers.length > 5
      ? !isFullContentVisible
        ? <>
          {callStackViewers.slice(0, 5)}
          <Button onClick={toggleShowMore} theme="warning" size="small" style={{ display: 'block', margin: '0 auto' }}> 显示更多... </Button>
          </>
        : <>
          {callStackViewers}
          <Button onClick={toggleShowMore} theme="warning" size="small" style={{ display: 'block', margin: '0 auto' }}> 显示更少 </Button>
          </>
      : callStackViewers
      }
    </>
    </Panel>
    </Collapse>
  </>;
};


CallStackViewers.propTypes = {
  stacksObjects: PropTypes.object,
  type: PropTypes.string, // number / memory
};

CallStackViewers.defaultProps = {
  type: "number",
};

// CallStackViewer 将单个调用栈渲染
// Param:
//  stacks: {  //最前面的是最底部的调用栈
//    "func": "foo",  // 函数名
//    "type": "xxx",  // 函数类型
//    "file": "/home/foo/bar.go" // 文件路径
//    "popconfirm": React.ReactNode, // 弹出框内容
//    "sourceCode": true,
// }
//  num: 2324
//  type: "number"/"memory"
// Return:
//  单条调用栈的lists
const CallStackViewer = (stacks, num, type) => {
  const ButtonList = [];
  let useInfo = `${num}次`;
  if (type == "memory") {
    useInfo = `${num}MB`;
  }
  const header = (<Tag
      style={{ marginBottom: '0px' }}
      size="mid"
      theme="warning"
      color="#fa9550"
      >
      相关调用栈(使用量为{useInfo})
    </Tag>);
  for (let j = stacks.length - 1; j >= 0; j--) {
    const currentStackData = stacks[j];
    ButtonList.push(<>
    {/* <Button size="small">{callList[j]}</Button> */}
    {/* <Popconfirm
      theme={'default'}
      content={""}
      popupProps={{
      placement: 'bottom',
      overlayStyle: { width: '870px', maxWidth: '870px' } }}
      confirmBtn={
        <></>
      }
      cancelBtn={
        <></>
      }
    > */}
      {
       !(currentStackData.sourceCode) // 当前函数不存在源代码
         ? <Button
      size="small" variant="outline" theme="primary" shape="round"
      ><u>
      {currentStackData.func}
      </u></Button>
         : <Button // 当前函数为热点函数，可能存在源码
      size="small"  theme="warning" color="#ffd9c2" variant="outline" shape="round"
      ><u>{currentStackData.func}
      </u>
      </Button>
      }
      {/* </Popconfirm> */}
    </>);
    ButtonList.push(<p style={{ marginTop: '1px' }}>➤</p>);
  }
  ButtonList.pop();
  return <Card bordered={false} header={header}>
      <Space breakLine={true}
          maxWidth={100}
      >
        {ButtonList}
      </Space>
    </Card>;
};
